<template>
  <div class="bg-white overflow-hidden shadow rounded-lg px-5 py-4">
    <div class="flex items-center justify-between">
      <h3 class="text-lg font-semibold text-wave mb-5">Annual Returns</h3>

    <select
      v-model="selectedBenchmark"
      @change="loadReturns"
      class="shadow-sm focus:ring-gray-500 focus:border-gray-dark block text-md border-gray-dark bg-gray-200 rounded-md ml-2 py-1 self-center"
    >
      <option v-for="benchmark in benchmarks" :key="benchmark" :value="benchmark">
        {{ benchmark }}
      </option>
    </select>

    </div>

    <div class="mt-2">
      <j-table :items="returnsData" :fields="fields"></j-table>
    </div>
  </div>
</template>

<script>
import AnnualReturns from "../../lib/AnnualReturns";
import Constants from "../../lib/Constants";
import JTable from "../../components/JTable.vue";

export default {
  name: "AnnualReturns",

  components: {
    JTable,
  },

  props: ["strategy"],

  data: () => {
    return {
      returnsData: [],
      selectedBenchmark: "CSI_300",
      benchmarks: Constants.benchmarkNames(),
    };
  },

  mounted: function () {
    this.loadReturns();
  },

  watch: {
    strategy() {
      this.loadReturns()
    }
  },

  methods: {
    loadReturns() {
      AnnualReturns.getAnnualReturnsData(
        this.strategy,
        this.selectedBenchmark
      ).then(this.setReturns);
    },

    setReturns(returns) {
      this.returnsData = returns;
    },

    percentFormatter(num) {
      return num.toFixed(2) + "%";
    },
  },

  computed: {
    fields() {
      return [
        {
          key: "year",
          label: "Year",
          sortable: true,
        },
        {
          key: "strategy",
          label: this.strategy,
          sortable: true,
          formatter: this.percentFormatter,
        },
        {
          key: "benchmark",
          label: this.selectedBenchmark,
          sortable: true,
          formatter: this.percentFormatter,
        },
        {
          key: "difference",
          label: "+/-",
          sortable: true,
          formatter: this.percentFormatter,
          colorFunc: (val) => val > 0 ? "#DDF7F6" : "#FFE1E1"
        },
      ];
    },
  },
};
</script>
