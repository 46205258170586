import * as R from "ramda"
import * as RA from "ramda-adjunct"

const benchmarkData = require("../data/benchmark_performance.json")
const strategyData = require("../data/strategy_performance.json")

function getData(strategyName, benchmarkName) {
  const timePeriods = R.keys(strategyData["ARPBL_M1_V1"])

  return R.map((timePeriod) => {

    const getReturn = (key, dataset) => R.pipe(R.pathOr("0", [key, timePeriod]), parseFloat, R.ifElse(RA.isNaN, R.always(0), R.identity))(dataset)

    const strategyPercent = getReturn(strategyName, strategyData)
    const benchmarkPercent = getReturn(benchmarkName, benchmarkData)

    return {
      timePeriod,
      strategyPercent,
      benchmarkPercent,
      difference:  strategyPercent - benchmarkPercent
    }
  }, timePeriods)
}

export default class Performance {

  static getPerformanceData(strategyName, benchmarkName) {
    return Promise.resolve(getData(strategyName, benchmarkName))
  }
}
