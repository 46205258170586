<template>
  <div class="bg-white overflow-hidden shadow rounded-lg px-5 py-4 flex flex-col items-stretch">
    <div class="flex items-center justify-between">
      <h3 class="text-lg font-semibold text-wave mb-5">Cumulative Growth</h3>

      <select
        v-model="selectedBenchmark"
        @change="loadReturns"
        class="shadow-sm focus:ring-gray-500 focus:border-gray-dark block text-md border-gray-dark bg-gray-200 rounded-md ml-2 py-1 self-center"
      >
        <option
          v-for="benchmark in benchmarks"
          :key="benchmark"
          :value="benchmark"
        >
          {{ benchmark }}
        </option>
      </select>
    </div>

    <div class="mt-2 self-stretch h-full">
      <canvas id="cumulativeStrategy" />
    </div>

  </div>
</template>

<script>
import Constants from "../../lib/Constants";

import StrategyReturns from "../../lib/StrategyReturns";

import * as R from "ramda"

import Chart from "chart.js";

Chart.defaults.global.defaultFontFamily = "'Lato', 'sans-serif'";
Chart.defaults.global.defaultFontSize = 14;
Chart.defaults.global.defaultFontColor = "#1A202C";

export default {
  name: "AnnualReturns",

  components: {
  },

  props: ["strategy"],

  data: () => {
    return {
      returnsData: [],
      benchmarkData: [],
      selectedBenchmark: "CSI_300",
      benchmarks: Constants.benchmarkNames(),
      chart: null
    };
  },

  mounted: function () {
    this.loadReturns();
  },

  watch: {
    strategy() {
      this.loadReturns();
    },
  },

  methods: {
    loadReturns() {
      this.setReturns(StrategyReturns.cumulativeReturns(this.strategy))
      this.setBenchmarkData(StrategyReturns.cumulativeReturns(this.selectedBenchmark, true))
      this.renderCumulativeReturns()
    },

    setReturns(returns) {
      this.returnsData = returns;
    },

    setBenchmarkData(returns) {
      this.benchmarkData = returns;
    },

    percentFormatter(num) {
      return num.toFixed(2) + "%";
    },

    renderCumulativeReturns() {
      let ctx = document.getElementById("cumulativeStrategy");

      if(this.chart) {
        this.chart.destroy()
      }

      let chart = new Chart(ctx, {
        type: "line",
        data: {
          datasets: [{
            fill: false,
            label: this.strategy,
            backgroundColor: "#9EDEC6",
            pointRadius: 0,
            borderColor: "#9EDEC6",
            data: R.clone(this.chartData),
          },
          {
            fill: false,
            label: this.selectedBenchmark,
            backgroundColor: "#DEEBF7",
            pointRadius: 0,
            borderColor: "#DEEBF7",
            data: R.clone(this.benchmarkChartData),
          }],
        },
        options: {
          responsive:true,
          maintainAspectRatio: false,
          legend: {
            display: true,
          },
          fill: false,
          animation: {
            duration: 0,
          },
          scales: {
            yAxes: [{ type: "linear" }],
            xAxes: [
              {
                type: "time",
                distribution: "linear",
                time: {
                  unit: "day",
                  stepSize: 365,
                  displayFormats: {
                    day: "YYYY",
                  },
                },
              },
            ],
          },
        },
      });

      this.chart = chart;
    },
  },

  computed: {
    chartData() {
      return StrategyReturns.toChartData(this.returnsData);
    },

    benchmarkChartData() {
      return StrategyReturns.toChartData(this.benchmarkData);
    },
  },
};
</script>
