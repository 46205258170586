<template>
  <div>
    <nav-bar />
    <div class="py-10">
      <header>
        <div
          class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between align-middle"
        >
          <div class="flex">
            <h1 class="text-3xl font-bold leading-tight text-wave">
              Signal Details
            </h1>
            <select
              v-model="selectedSignal"
              class="shadow-sm focus:ring-gray-500 focus:border-gray-500 block text-md border-gray-500 bg-gray-200 rounded-md ml-2 py-1 self-center"
              :disabled="loading"
            >
              <option
                v-for="signal in signals"
                :key="signal['id_name']"
                :value="signal['id_name']"
                >{{ signal["id_name"] }}</option
              >
            </select>
          </div>

          <h4 class="self-center text-lg text-wave">
            {{ signalDescription }}
          </h4>
        </div>
      </header>
      <main>
        <div class="max-w-7xl mx-auto sm:px-6 lg:px-8 py-4">
          <div class="flex flex-wrap">
            <div class="w-full md:w-2/5">
              <response-chart
                :data="responseFunctionData"
                title="Response Function"
                subtitle="Percentile vs Response"
                chartId="trendChart"
              />
              <response-chart-two
                :data="responseFunctionData"
                title="Response Function"
                subtitle="Tau vs Response"
                chartId="trendChart2"
              />
              <stats-and-params/>
            </div>

            <div class="w-full md:w-3/5">
              <backtest-section title="Preliminary Backtest" :data="returns" />
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import ResponseChart from "../components/ResponseChart.vue";
import ResponseChartTwo from "../components/ResponseChartTwo.vue";
import BacktestSection from "../components/BacktestSection.vue";
import NavBar from "../components/NavBar.vue";
import StatsAndParams from "../components/StatsAndParams.vue";
import * as R from "ramda";

// const returns = require("../data/returns.json");

export default {
  name: "Dashboard",
  mounted: function() {
    this.getSignalReturns();
    this.getResponseFunction();
    this.getSignals();

    if (this.signal) {
      this.selectedSignal = this.signal;
    }
  },
  props: ["signal"],
  data: () => {
    return {
      selectedSignal: "trend_6mo_example",
      signals: [],
      returns: {},
      apiUrl:
        "https://2bunl3ya09.execute-api.us-east-1.amazonaws.com/wave-prod",
      responseFunctionLoading: false,
      returnsLoading: false,
      responseFunctionData: {
        ordinate: [],
        abscissa: [],
        response_fn_mean: [],
        response_fn_stdv: [],
      },
    };
  },
  watch: {
    selectedSignal: function() {
      this.getSignalReturns();
      this.getResponseFunction();
    },
  },
  computed: {
    loading: function() {
      return this.responseFunctionLoading || this.returnsLoading;
    },
    signalDescription: function() {
      const current = R.head(
        R.filter((x) => x["id_name"] === this.selectedSignal, this.signals)
      );

      if (current) {
        return current["description"];
      } else {
        return "";
      }
    },
  },
  methods: {
    getSignalReturns: function() {
      this.returnsLoading = true;
      const self = this;

      setTimeout(function() {
        fetch(`${self.apiUrl}/signal/returns/${self.selectedSignal}`)
          .then((res) => res.json())
          // .then(res => console.log(res))
          .then((res) => {
            self.returns = res;
            self.returnsLoading = false;
          })
          .catch(() => console.log("Bad data"));
      }, 500);
    },
    getResponseFunction: function() {
      this.responseFunctionLoading = true;
      fetch(`${this.apiUrl}/signal/responsefcns/${this.selectedSignal}`)
        .then((res) => res.json())
        .then((res) => {
          this.responseFunctionLoading = false;
          this.responseFunctionData =
            res.signal_response_fcns[0].json_data_response_fcn;
        })
        .catch(() => console.log("Bad data"));
    },
    getSignals: function() {
      const self = this;

      setTimeout(function() {
        fetch(`${self.apiUrl}/signal_library`)
          .then((res) => res.json())
          .then((res) => {
            self.signals = res["signal_list"];
          });
      }, 1000);
    },
  },
  components: {
    ResponseChart,
    BacktestSection,
    NavBar,
    StatsAndParams,
    ResponseChartTwo
  },
};
</script>
