import * as R from "ramda";
import * as RA from "ramda-adjunct";

const benchmarkReturns = require("../data/benchmark_dailyreturn.json");
const strategyReturns = require("../data/strategy_dailyreturn.json");

export default class StrategyReturns {
  static dailyData(strategyName, benchmark = false) {
    const dataset = benchmark === true ? benchmarkReturns : strategyReturns

    const dates = R.keys(dataset)

    return R.map((date) => {
      const day = dataset[date]

      if(benchmark == true) {
        console.log(day)
        console.log(date)
      }

      const percentChange = R.pipe(
        R.pathOr("0", [strategyName]),
        parseFloat,
        R.ifElse(RA.isNaN, R.always(0), R.identity)
      )(day);

      return { date, percentChange };
    }, dates);
  }

  static cumulativeReturns(strategyName, benchmark = false) {
    const daily = StrategyReturns.dailyData(strategyName, benchmark)

    return R.reduce(
      (returns, x) => {
        const previous = returns.length == 0 ? 1 : returns[returns.length - 1].cumulative
        const cumulative = (1 + (x.percentChange / 100)) * previous

        return [
          ...returns,
          { ...x, cumulative  }
        ]
      },
      [],
      daily
    )
  }

  static toChartData(returns) {
    return R.map(day => {
      return {x: new Date(day.date), y: day.cumulative * 100}
    }, returns)
  }
}

window.jackReturns = StrategyReturns
