import * as R from "ramda"
import * as RA from "ramda-adjunct"

const benchmarkData = require("../data/benchmark_annualreturn.json")
const strategyData = require("../data/strategy_annualreturn.json")

function getData(strategyName, benchmarkName) {
  const years = R.keys(strategyData)

  return R.map((year) => {

    const getReturn = (key, dataset) => R.pipe(R.pathOr("0", [year, key]), parseFloat, R.ifElse(RA.isNaN, R.always(0), R.identity))(dataset)

    const strategy = getReturn(strategyName, strategyData)
    const benchmark = getReturn(benchmarkName, benchmarkData)

    return {
      year: parseInt(year),
      strategy,
      benchmark,
      difference: strategy - benchmark
    }
  }, years)
}

export default class AnnualReturns {
  static getAnnualReturnsData(strategyName, benchmarkName) {
    return Promise.resolve(getData(strategyName, benchmarkName))
  }
}

window.AnnualReturns = AnnualReturns