const apiUrl =
  "https://2bunl3ya09.execute-api.us-east-1.amazonaws.com/wave-prod";
import * as R from "ramda";
import { getCol } from "@/lib/chartHelpers";

function getHistorical(id_name) {
  return (
    fetch(`${apiUrl}/signal/returns/${id_name}`)
      .then((res) => res.json())
      // .then(res => console.log(res))
      .then((res) => {
        return res;
      })
  );
}

function encodedToDate(encoded) {
  const year = parseInt(encoded / 10000);
  const month = parseInt(encoded / 100) % 100;
  const day = encoded % 100;

  return new Date(year, month - 1, day);
}

const cumProd = R.reduce(
  (returns, x) => [...returns, returns[returns.length - 1] * (1 + x)],
  [1]
);

function zipMany(arrays) {
  let output = [];

  for (let i in arrays[0]) {
    output[i] = getCol(arrays, i);
  }

  return output;
}

function average(data) {
  var sum = R.reduce(
    function(sum, value) {
      return sum + value;
    },
    0,
    data
  );

  var avg = sum / data.length;
  return avg;
}

function cagr(data) {
  const avg = average(data);

  return Math.pow(1 + avg, 252) - 1;
}

function standardDeviation(values) {
  var avg = average(values);

  var squareDiffs = values.map(function(value) {
    var diff = value - avg;
    var sqrDiff = diff * diff;
    return sqrDiff;
  });

  var avgSquareDiff = average(squareDiffs);

  var stdDev = Math.sqrt(avgSquareDiff);
  return stdDev;
}

function volatility(values) {
  const stdDev = standardDeviation(values);
  return stdDev * Math.sqrt(252);
}

function sharpe(values) {
  return cagr(values) / volatility(values)
}

export {
  getHistorical,
  encodedToDate,
  cumProd,
  zipMany,
  cagr,
  standardDeviation,
  volatility,
  sharpe
};
