import * as R from "ramda"
import * as RA from "ramda-adjunct"

const benchmarkData = require("../data/benchmark_riskreturn.json")
const strategyData = require("../data/strategy_riskreturn.json")

function getData(strategyName, benchmarkName) {
  const indicators = R.keys(strategyData["ARPBL_M1_V1"])

  return R.map((indicator) => {

    const getReturn = (key, dataset) => R.pipe(R.pathOr("0", [key, indicator]), parseFloat, R.ifElse(RA.isNaN, R.always(0), R.identity))(dataset)

    const strategyPercent = getReturn(strategyName, strategyData)
    const benchmarkPercent = getReturn(benchmarkName, benchmarkData)

    return {
      indicator,
      strategyPercent,
      benchmarkPercent,
      difference:  strategyPercent - benchmarkPercent
    }
  }, indicators)
}

export default class RiskReturn {

  static getRiskReturnData(strategyName, benchmarkName) {
    return Promise.resolve(getData(strategyName, benchmarkName))
  }
}
