<template>
  <div>
    <nav-bar />
    <div class="py-10">
      <header>
        <div
          class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between align-middle"
        >
          <div class="flex">
            <h1 class="text-3xl font-bold leading-tight text-wave">
              Signal Library
            </h1>

            <select
              v-model="selectedGroup"
              class="shadow-sm focus:ring-gray-500 focus:border-gray-dark block text-md border-gray-dark bg-gray-200 rounded-md ml-2 py-1 self-center"
            >
              <option
                v-for="group in groupOptions"
                :key="group"
                :value="group"
                >{{ group }}</option
              >
            </select>
          </div>

          <h4 class="self-center text-lg text-wave hidden md:block">
            Select a signal to view details
          </h4>
        </div>
      </header>
      <main>
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div class="flex flex-wrap">
            <div
              class="w-full"
              v-for="signal in filteredSignals"
              :key="signal['id_name']"
            >
              <router-link :to="'/dashboard/' + signal['id_name']">
                <div
                  class="bg-white overflow-hidden shadow rounded-lg m-2 p-5 border-transparent hover:bg-gray-light cursor-pointer hover:border-gray-dark border-2"
                >
                  <div>
                    <h3 class="text-lg font-semibold text-wave mb-5">
                      {{ idToTitle(signal["id_name"]) }}
                    </h3>
                  </div>
                  <div class="flex flex-wrap space-between text-sm">
                    <div class="flex w-full md:w-2/3 space-between sm:content-start">
                      <div class="w-full md:w-3/5 pr-1">
                        <p>
                          <span class="font-semibold">Signal Description:</span>
                          {{ signal["description"] }}
                        </p>
                        <p>
                          <span class="font-semibold">Signal Group:</span>
                          {{ signal["signal_group"] }}
                        </p>
                        <p>
                          <span class="font-semibold">Signal type:</span>
                          {{ signal["sig_type"] }}
                        </p>
                      </div>
                      <div>
                        <p>
                          <span class="font-semibold">Signal Formula: </span>
                        </p>
                        <vue-mathjax :formula="signal['formula']"></vue-mathjax>
                      </div>
                    </div>
                    <div class="pt-5 md:pt-0">
                      <p>
                        <span class="font-semibold">Status: </span>
                        <span class="text-yellow-500">Pending review</span>
                      </p>
                      <p>
                        <span class="font-semibold">Submitted by: </span>
                        Patrick MacDonald
                      </p>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import * as R from "ramda";
import NavBar from "../components/NavBar.vue";
import { VueMathjax } from "vue-mathjax";

export default {
  name: "SignalLibrary",
  data: () => {
    return {
      apiUrl:
        "https://2bunl3ya09.execute-api.us-east-1.amazonaws.com/wave-prod",
      signals: [],
      selectedGroup: "all",
    };
  },
  components: {
    NavBar,
    "vue-mathjax": VueMathjax,
  },
  mounted: function() {
    this.getSignals();
  },
  methods: {
    getSignals: function() {
      fetch(`${this.apiUrl}/signal_library`)
        .then((res) => res.json())
        .then((res) => {
          this.signals = res["signal_list"];
        });
    },
    idToTitle: function(id) {
      const capFirst = (str) => str.charAt(0).toUpperCase() + str.slice(1);

      return R.join(" ", R.map(capFirst, R.split("_", id)));
    },
  },
  computed: {
    filteredSignals: function() {
      if (this.selectedGroup === "all") {
        return this.signals;
      }

      const isSelected = (signal) => signal.signal_group === this.selectedGroup;
      return R.filter(isSelected, this.signals);
    },
    groupOptions: function() {
      const getGroup = (signal) => signal.signal_group;
      return ["all", ...R.uniq(R.map(getGroup, this.signals))];
    },
  },
};
</script>
