<template>
  <div
    class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200 my-2 md:ml-1"
  >
    <div class="px-4 py-5 sm:px-6">
      <div class="flex justify-between">
        <h3 class="text-lg leading-6 font-medium text-wave">
          {{ title }}
        </h3>
        <table class="w-1/2">
          <tbody>
            <tr>
              <td class="w-40">Weighting</td>
              <td class="w-20 font-bold">
                <select
                  v-model="returnWeight"
                  class="shadow-sm focus:ring-gray-500 focus:border-gray-dark block w-full text-md border-gray-dark bg-gray-200 rounded-md py-1 self-center"
                >
                  <option
                    v-for="weight in weightingOptions"
                    :value="weight"
                    :key="weight"
                    >{{ weight }}</option
                  >
                </select>
              </td>
            </tr>
            <tr>
              <td class="w-40">Min. Securities</td>
              <td class="w-20 font-bold">100</td>
            </tr>
            <tr>
              <td class="w-40">Min. Signal Threshold</td>
              <td class="w-20 font-bold">X</td>
            </tr>
            <tr>
              <td class="w-40">Spread Plot</td>
              <td class="w-20 font-bold">True</td>
            </tr>
            <tr>
              <td class="w-40">Specific Year</td>
              <td class="w-20 font-bold">All</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div>
      <div>
        <!-- Culmulative returns section -->
        <div class="px-4 py-6">
          <div class="flex justify-between items-center">
            <h3 class="text-lg p-2">Cumulative Returns</h3>
            <div class="flex self-center">
              <p class="mr-2">
                Logarithmic scale
              </p>
              <button
                type="button"
                @click="toggleLog"
                class="self-center flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-dark"
                aria-pressed="false"
              >
                <span class="sr-only">Logarithmic scale</span>
                <span
                  aria-hidden="true"
                  class="pointer-events-none absolute bg-white w-full h-full rounded-md"
                ></span>
                <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
                <span
                  aria-hidden="true"
                  :class="
                    'bg-gray-200 pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200 ' +
                      (logScale ? 'bg-gray-dark' : 'bg-gray-200')
                  "
                ></span>
                <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                <span
                  aria-hidden="true"
                  :class="
                    'translate-x-0 pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200 ' +
                      (logScale ? 'translate-x-5' : 'translate-x-0')
                  "
                ></span>
              </button>
            </div>
          </div>
          <canvas id="cumulativeReturnsChart" />

          <div class="w-full p-6">
            <table v-if="ready" class="w-full">
              <thead>
                <tr class="border-b">
                  <td></td>
                  <td class="font-semibold text-right" v-for="label of percentileLabels" :key="label + 'stats'">{{ label }}</td>
                </tr>
              </thead>
              <tbody>
                <tr class="border-b">
                  <td class="w-40">CAGR</td>
                  <td class="w-20 text-right" v-for="val of cagrValues" :key="'cagr' + val">{{ (val * 100).toFixed(2) }}%</td>
                </tr>
                <tr class="border-b">
                  <td class="w-40">Volatility</td>
                  <td class="w-20 text-right" v-for="val of volatilities" :key="'vola' + val">{{ (val * 100).toFixed(2) }}%</td>
                </tr>
                <tr class="border-b">
                  <td class="w-40">Sharpe</td>
                  <td class="w-20 text-right" v-for="val of sharpes" :key="'shar' + val">{{ (val).toFixed(2) }}</td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
      <div class="px-4">
        <!-- trailing 12 month summary -->
        <div class="py-6 border-t">
          <div class="flex justify-between">
            <h3 class="text-lg p-2">Trailing 12 Month Summary</h3>
            <div class="flex">
              <span class="self-center mr-2">Benchmark</span>
              <div class="self-center">
                <select
                  v-model="benchmark"
                  class="shadow-sm focus:ring-gray-500 focus:border-gray-dark block w-full text-md border-gray-dark bg-gray-200 py-1 rounded-md self-center"
                >
                  <option
                    v-for="bench in benchmarkOptions"
                    :value="bench"
                    :key="bench"
                    >{{ bench }}</option
                  >
                </select>
              </div>
            </div>
          </div>
          <canvas id="trailingSummary" />
        </div>
      </div>

      <div class="flex flex-wrap">
        <div class="w-1/2 p-6">
          <table>
            <thead>
              <tr class="border-b">
                <td></td>
                <td class="font-semibold text-right">Signal</td>
                <td class="font-semibold text-right pl-4">Benchmark</td>
              </tr>
            </thead>
            <tbody>
              <tr class="border-b">
                <td class="w-40">Avg. return</td>
                <td class="w-20 text-right">{{ signalStats.average }}%</td>
                <td class="w-20 text-right">{{ benchmarkStats.average }}%</td>
              </tr>
              <tr class="border-b">
                <td class="w-40">Best</td>
                <td class="w-20 text-right">{{ signalStats.best }}%</td>
                <td class="w-20 text-right">{{ benchmarkStats.best }}%</td>
              </tr>
              <tr class="border-b">
                <td class="w-40">Worst</td>
                <td class="w-20 text-right">{{ signalStats.worst }}%</td>
                <td class="w-20 text-right">{{ benchmarkStats.worst }}%</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="w-1/2 flex flex-row justify-around items-center">
          <div class="flex flex-col">
            <button
              class="mb-3 px-2 py-1 border shadow-sm font-medium rounded-md focus:outline-none sm:text-sm bg-white text-wave border-gray-dark hover:bg-gray-dark hover:text-white"
            >
              Print Report
            </button>
            <button
              class="mb-1 px-2 py-1 border shadow-sm font-medium rounded-md focus:outline-none sm:text-sm bg-white text-wave border-gray-dark hover:bg-gray-dark hover:text-white"
            >
              Share Report
            </button>
          </div>
          <div class="flex flex-col">
            <button
              class="mb-3 px-2 py-1 border shadow-sm font-medium rounded-md focus:outline-none sm:text-sm bg-white text-wave border-gray-dark hover:bg-gray-dark hover:text-white"
            >
              Add Comment
            </button>
            <button
              class="mb-1 px-2 py-1 border shadow-sm font-medium rounded-md focus:outline-none sm:text-sm bg-white text-wave border-gray-dark hover:bg-gray-dark hover:text-white"
            >
              Review Comments
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";
import * as R from "ramda";
import { getCol } from "@/lib/chartHelpers";

Chart.defaults.global.defaultFontFamily = "'Lato', 'sans-serif'";
Chart.defaults.global.defaultFontSize = 14;
Chart.defaults.global.defaultFontColor = "#1A202C";

const benchmarkData = require("../data/benchmarks.json");

const mapIndexed = R.addIndex(R.map);

function standardDeviation(values){
  var avg = average(values);
  
  var squareDiffs = values.map(function(value){
    var diff = value - avg;
    var sqrDiff = diff * diff;
    return sqrDiff;
  });
  
  var avgSquareDiff = average(squareDiffs);

  var stdDev = Math.sqrt(avgSquareDiff);
  return stdDev;
}

function average(data){
  var sum = R.reduce(function(sum, value){
    return sum + value;
  }, 0, data);

  var avg = sum / data.length;
  return avg;
}

export default {
  name: "BacktestSection",
  data: () => {
    return {
      logScale: true,
      activeI: [0, 1, 2, 3, 4],
      alg: "t",
      benchmark: "sse_comp",
      returnWeight: "equal",
      colors: [
        ["#E7ECE4", "#E7ECE4"],
        ["#AFABAB", "#AFABAB"],
        ["#E3C3B5", "#E3C3B5"],
        ["#908292", "#908292"],
        ["#DEEBF7", "#DEEBF7"],
        ["#6DB8E1", "#6DB8E1"],
        ["#9EDEC6", "#9EDEC6"],
      ],
    };
  },
  props: {
    data: Object,
    title: String,
    chartId: String,
  },
  mounted: function() {
    this.renderCumulativeReturns();
    this.renderBar();
  },
  computed: {
    ready: function() {
      return this.data.dates !== undefined;
    },
    dates: function() {
      if (this.ready) {
        return R.map(this.encodedToDate, this.data.dates);
      } else {
        return [];
      }
    },
    percentileLabels: function() {
      if (!this.ready) {
        return [];
      }

      return mapIndexed((_val, idx) => {
        return `Q${idx + 1}`;
      }, this.data.percentiles);
    },
    avgDailyreturns: function() {
      if(this.ready) {
        const returns = this.activeReturnData.returns;

        const getAvg = (ret) => {

          const total = R.reduce(R.add, 0, ret)

          return total / ret.length
        }

        return R.map((x) => {
          return getAvg(getCol(returns, x))
        }, this.activeI)
      } else {
        return [0,0,0,0,0]
      } 
    },
    standardDeviations: function() {
      if(this.ready) {
        const returns = this.activeReturnData.returns;

        return R.map((x) => {
          return standardDeviation(getCol(returns, x))
        }, this.activeI)
      } else {
        return [0,0,0,0,0]
      } 
    },
    volatilities: function() {
      return R.map((x) => {
        return (x * Math.sqrt(252))
      }, this.standardDeviations)
    },
    sharpes: function() {
      const pairs = R.zip(this.cagrValues, this.volatilities)

      return R.map((x) => {
        return (x[0] / x[1])
      }, pairs)
    },
    cagrValues: function() {
      if (this.ready) {
        const avgDailyreturns = this.avgDailyreturns;

        const avgToCAGR = (avg) => {
          return (Math.pow((1 + avg), 252) - 1)
        }

        return R.map(avgToCAGR, avgDailyreturns)

      } else {
        return []
      }
    },
    signalData: function() {
      if (!this.ready) {
        return [];
      }

      const { activeReturnData, dates } = this;

      const pairs = R.zip(getCol(activeReturnData.returns, 4), dates);

      const yearAgo = new Date().setFullYear(new Date().getFullYear() - 1);
      const lessThanYear = (p) => p[1] > yearAgo;
      const oneYearPairs = R.filter(lessThanYear, pairs);

      const groupSize = Math.floor(oneYearPairs.length / 12);

      var groupsOf = R.curry(function group(n, list) {
        return R.isEmpty(list)
          ? []
          : R.prepend(R.take(n, list), group(n, R.drop(n, list)));
      });

      const monthGroups = R.filter(
        (g) => g.length == groupSize,
        groupsOf(groupSize, oneYearPairs)
      );

      const prod = (x) => {
        return parseFloat(
          ((R.reduce((acc, x) => acc * (1 + x[0]), 1)(x) - 1) * 100).toFixed(2)
        );
      };

      const monthlyReturns = R.map(prod);

      return monthlyReturns(monthGroups);
    },
    benchmarkData: function() {
      return benchmarkData.benchmarks[this.benchmark].data;
    },
    benchmarkStats: function() {
      const { benchmarkData, getRollupStats } = this;

      return getRollupStats(benchmarkData);
    },
    signalStats: function() {
      if (!this.ready) {
        return { average: 0, best: 0, worst: 0 };
      }

      const { signalData, getRollupStats } = this;

      return getRollupStats(signalData);
    },
    benchmarkOptions: function() {
      return R.keys(benchmarkData.benchmarks);
    },
    benchmarkDiff: function() {
      const { signalData, benchmarkData } = this;

      const joinData = R.zip;
      const diffPair = (pair) => (pair[0] - pair[1]).toFixed(2);
      const diffPairs = R.map(diffPair);

      return R.pipe(joinData, diffPairs)(signalData, benchmarkData);
    },
    summaryDatasets: function() {
      const data1 = this.signalData;

      const data2 = this.benchmarkData;

      const data3 = this.benchmarkDiff;

      return [
        {
          label: "Signal",
          backgroundColor: this.colors[1][1],
          barThickness: "flex",
          data: data1,
        },
        {
          label: "Benchmark",
          backgroundColor: this.colors[0][1],
          barThickness: "flex",
          data: data2,
        },
        {
          label: "Alpha",
          backgroundColor: this.colors[5][1],
          barThickness: "flex",
          data: data3,
        },
      ];
    },
    activeReturnData: function() {
      if (this.ready) {
        const { returnWeight } = this;
        const allData = this.data.signal_return_data;

        return R.head(R.filter((x) => x.weighting == returnWeight, allData));
      } else {
        return {};
      }
    },
    weightingOptions: function() {
      if (!this.ready) {
        return [];
      }

      const allData = this.data.signal_return_data;

      return R.map(R.prop("weighting"), allData);
    },
    returns: function() {
      if (this.ready) {
        const returns = this.activeReturnData.returns;

        const cumprod = R.reduce(
          (returns, x) => [...returns, returns[returns.length - 1] * (1 + x)],
          [1]
        );

        return R.map((x) => cumprod(getCol(returns, x)), this.activeI);
      } else {
        return [[]];
      }
    },
    returnsPoints: function() {
      const points = R.map((returns) => {
        const xyPairs = R.zip(this.dates, returns);

        return R.map(this.pairToCoords, xyPairs);
      }, this.returns);

      return points;
    },
    returnsDatasets: function() {
      const mapIndexed = R.addIndex(R.map);

      return mapIndexed((data, index) => {
        return {
          fill: false,
          label: this.percentileLabels[index],
          backgroundColor: this.colors[index][0],
          pointRadius: 0,
          borderColor: this.colors[index][1],
          data: data,
        };
      }, this.returnsPoints);
    },
  },
  watch: {
    summaryDatasets: function() {
      this.trailingSummaryChart.data.datasets = this.summaryDatasets;
      this.trailingSummaryChart.update();
    },
    returnsDatasets: function() {
      this.chart.data.datasets = this.returnsDatasets;
      this.chart.update();
    },
    logScale: function() {
      this.chart.options.scales.yAxes = this.logScale
        ? [{ type: "logarithmic" }]
        : [{ type: "linear" }];
      this.chart.update();
    },
  },
  methods: {
    getRollupStats(data) {
      const sorted = R.sort((x, y) => y - x, data);
      const average = (
        R.reduce((acc, curr) => acc + curr, 0, data) / data.length
      ).toFixed(2);
      const best = sorted[0].toFixed(2);
      const worst = sorted[sorted.length - 1].toFixed(2);

      return { average, best, worst };
    },
    isactiveI(z) {
      return R.includes(z, this.activeI);
    },
    toggleLog() {
      this.logScale = !this.logScale;
    },
    chooseZ(i) {
      if (!this.isactiveI(i)) {
        this.activeI = R.sort((a, b) => a - b, R.append(i, this.activeI));
      } else {
        this.activeI = R.filter((x) => x !== i, this.activeI);
      }
    },
    encodedToDate: function(encoded) {
      const year = parseInt(encoded / 10000);
      const month = parseInt(encoded / 100) % 100;
      const day = encoded % 100;

      return new Date(year, month - 1, day);
    },
    pairToCoords: function(pair) {
      return { x: pair[0], y: pair[1] };
    },
    renderCumulativeReturns() {
      let ctx = document.getElementById("cumulativeReturnsChart");

      let chart = new Chart(ctx, {
        type: "line",
        data: {
          datasets: this.returnsDatasets,
        },
        options: {
          aspectRatio: 2.5,
          legend: {
            display: true,
          },
          fill: false,
          // animation: {
          //   duration: 0,
          // },
          scales: {
            yAxes: [{ type: "logarithmic" }],
            xAxes: [
              {
                type: "time",
                distribution: "linear",
                time: {
                  unit: "day",
                  stepSize: 365,
                  displayFormats: {
                    day: "YYYY",
                  },
                },
              },
            ],
          },
        },
      });

      this.chart = chart;
    },
    renderBar() {
      let ctx = document.getElementById("trailingSummary");

      let chart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: [-12, -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1],
          datasets: this.summaryDatasets,
        },
        options: {
          // animation: {
          //   duration: 0,
          // },
          aspectRatio: 3.5,
          scales: {
            yAxes: [
              {
                ticks: {
                  stepSize: 10,
                  callback: (x) => `${x}%`,
                },
              },
            ],
          },
        },
      });

      this.trailingSummaryChart = chart;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
