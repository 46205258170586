<template>
  <div>
    <nav-bar />
    <div class="py-10">
      <header>
        <div
          class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between align-middle"
        >
          <div class="flex">
            <h1 class="text-3xl font-bold leading-tight text-wave">
              Signal Combination
            </h1>
          </div>

          <h4 class="self-center text-lg text-wave hidden md:block">
            <!-- Select a signal to view details -->
          </h4>
        </div>
      </header>
      <main>
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div class="flex flex-wrap">
            <div class="w-full md:w-1/4">
              <div
                class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200 my-2 md:mr-1"
              >
                <div>
                  <h3
                    class="text-lg leading-6 font-bold text-wave self-center px-4 py-5 sm:px-6"
                  >
                    Signal Library
                  </h3>
                </div>

                <!-- Group -->
                <div class="px-4 py-5 sm:px-6">
                  <div class="mb-2" v-for="group in groups" :key="group.name">
                    <h4 class="text-md font-semibold text-wave mb-2">
                      {{ group.name }}
                    </h4>

                    <div class="pl-2">
                      <div
                        v-for="signal in group.signals"
                        :key="signal.name"
                        class="relative flex items-start mb-2"
                      >
                        <div class="flex items-center h-5">
                          <input
                            v-model="selected"
                            :id="'check_' + signal.id_name"
                            :value="signal.id_name"
                            name="comments"
                            type="checkbox"
                            class="focus:ring-gray-500 h-4 w-4 text-gray-dark border-gray-300 rounded"
                          />
                        </div>
                        <div class="ml-3 text-sm">
                          <label for="comments" class="font-medium text-wave">{{
                            signal.id_name
                          }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-3/4">
              <div
                class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200 my-2 md:ml-1"
              >
                <div class="flex justify-between px-4 py-5 sm:px-6">
                  <h3 class="text-lg leading-6 font-bold text-wave self-center">
                    Cumlulative Returns
                  </h3>
                  <div class="flex self-center">
                    <p class="mr-2">
                      Logarithmic scale
                    </p>
                    <button
                      type="button"
                      @click="toggleLog"
                      class="self-center flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-dark"
                      aria-pressed="false"
                    >
                      <span class="sr-only">Logarithmic scale</span>
                      <span
                        aria-hidden="true"
                        class="pointer-events-none absolute bg-white w-full h-full rounded-md"
                      ></span>
                      <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
                      <span
                        aria-hidden="true"
                        :class="
                          'bg-gray-200 pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200 ' +
                            (logScale ? 'bg-gray-dark' : 'bg-gray-200')
                        "
                      ></span>
                      <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                      <span
                        aria-hidden="true"
                        :class="
                          'translate-x-0 pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200 ' +
                            (logScale ? 'translate-x-5' : 'translate-x-0')
                        "
                      ></span>
                    </button>
                  </div>
                </div>

                <!-- Group -->
                <div class="px-4 py-5 sm:px-6">
                  <canvas id="cumulativeReturnsChart" />
                </div>
              </div>

              <div
                class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200 my-2 md:ml-1"
              >
                <div class="flex justify-between px-4 py-5 sm:px-6">
                  <h3 class="text-lg leading-6 font-bold text-wave self-center">
                    Cumlulative Performance
                  </h3>
                </div>

                <!-- Group -->
                <div class="px-4 py-5 sm:px-6 flex">
                  <table class="w-1/2">
                    <thead>
                      <tr class="border-b">
                        <td></td>
                        <td class="text-right font-semibold">Combined</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border-b">
                        <td class="w-40">CAGR</td>
                        <td class="w-20 text-right">
                          {{ (cagr * 100).toFixed(2) }}%
                        </td>
                      </tr>
                      <tr class="border-b">
                        <td class="w-40">Volatility</td>
                        <td class="w-20 text-right">
                          {{ (volatility * 100).toFixed(2) }}%
                        </td>
                      </tr>
                      <tr class="border-b">
                        <td class="w-40">Sharpe</td>
                        <td class="w-20 text-right">
                          {{ (sharpe).toFixed(2) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div class="w-1/2 flex justify-end items-center">
                    <div class="flex flex-col justify-around">
                      <button
                        class="mb-3 px-2 py-1 border shadow-sm font-medium rounded-md focus:outline-none sm:text-sm bg-white text-wave border-gray-dark hover:bg-gray-dark hover:text-white"
                      >
                        Comments
                      </button>
                      <button
                        class="mb-1 px-2 py-1 border shadow-sm font-medium rounded-md focus:outline-none sm:text-sm bg-white text-wave border-gray-dark hover:bg-gray-dark hover:text-white"
                      >
                        Save Combined
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import * as R from "ramda";
import NavBar from "../components/NavBar.vue";
import {
  getHistorical,
  encodedToDate,
  cumProd,
  zipMany,
  cagr,
  sharpe,
  volatility,
} from "../lib/dataHelpers.js";
import { getCol } from "@/lib/chartHelpers";
import Chart from "chart.js";

export default {
  name: "SignalCombination",
  data: () => {
    return {
      apiUrl:
        "https://2bunl3ya09.execute-api.us-east-1.amazonaws.com/wave-prod",
      signals: [],
      signalData: [],
      datasets: [],
      selected: [],
      allReturns: [],
      chart: null,
      logScale: true,
      colors: [
        ["#E7ECE4", "#E7ECE4"],
        ["#AFABAB", "#AFABAB"],
        ["#E3C3B5", "#E3C3B5"],
        ["#908292", "#908292"],
        ["#DEEBF7", "#DEEBF7"],
        ["#6DB8E1", "#6DB8E1"],
        ["#9EDEC6", "#9EDEC6"],
        // Duplicates
        ["#E7ECE4", "#E7ECE4"],
        ["#AFABAB", "#AFABAB"],
        ["#E3C3B5", "#E3C3B5"],
        ["#908292", "#908292"],
        ["#DEEBF7", "#DEEBF7"],
        ["#6DB8E1", "#6DB8E1"],
        ["#9EDEC6", "#9EDEC6"],
      ],
    };
  },
  components: {
    NavBar,
  },
  mounted: function() {
    this.getSignals();
  },
  methods: {
    toggleLog() {
      this.logScale = !this.logScale;
    },
    getSignals: function() {
      fetch(`${this.apiUrl}/signal_library`)
        .then((res) => res.json())
        .then((res) => {
          this.signals = res["signal_list"];
        });
    },
    formatDataset: function(name, returns, dates) {
      const cumReturns = cumProd(returns);

      const xyPairs = R.zip(dates, cumReturns);

      const points = R.map((pair) => {
        return { x: pair[0], y: pair[1] };
      }, xyPairs);

      const index = R.indexOf(name, this.signalNames);

      return {
        fill: false,
        label: name,
        backgroundColor: this.colors[index][0],
        pointRadius: 0,
        borderColor: this.colors[index][1],
        data: points,
      };
    },
    getData: async function() {
      const { signals } = this;

      for (let signal of signals) {
        const name = signal.id_name;

        try {
          let res = await getHistorical(name);

          const dates = R.map(encodedToDate, res.dates);
          const returns = getCol(res.signal_return_data[0].returns, 4);

          this.allReturns.push({ name, returns });

          const dataset = this.formatDataset(name, returns, dates);

          this.datasets.push(dataset);
        } catch (err) {
          console.log("Bad data for " + name);
        }
      }
    },
    getActiveDates: function() {
      const { activeDatasets } = this;

      if (!activeDatasets[0]) {
        return [];
      }

      return R.map((point) => {
        return point.x;
      }, activeDatasets[0].data);
    },
    renderChart() {
      let ctx = document.getElementById("cumulativeReturnsChart");

      let chart = new Chart(ctx, {
        type: "line",
        data: {
          datasets: this.activeDatasets,
        },
        options: {
          aspectRatio: 2.5,
          legend: {
            display: true,
          },
          fill: false,
          animation: {
            duration: 0,
          },
          scales: {
            yAxes: [{ type: "logarithmic" }],
            xAxes: [
              {
                type: "time",
                distribution: "linear",
                time: {
                  unit: "day",
                  stepSize: 365,
                  displayFormats: {
                    day: "YYYY",
                  },
                },
              },
            ],
          },
        },
      });

      this.chart = chart;
    },
  },
  watch: {
    signals: async function() {
      await this.getData();
    },
    activeDatasets: function() {
      if (this.chart) {
        this.$nextTick(() => {
          if (this.combinedSignal) {
            this.chart.data.datasets = [
              ...this.activeDatasets,
              this.combinedSignal,
            ];
          } else {
            this.chart.data.datasets = this.activeDatasets;
          }

          this.chart.update();
        });
      } else {
        this.renderChart();
      }
    },
    logScale: function() {
      this.chart.options.scales.yAxes = this.logScale
        ? [{ type: "logarithmic" }]
        : [{ type: "linear" }];
      this.chart.update();
    },
  },
  computed: {
    sharpe: function() {
      if (this.combinedDailyReturns.length == 0) {
        return 0;
      }

      return sharpe(this.combinedDailyReturns);
    },
    volatility: function() {
      if (this.combinedDailyReturns.length == 0) {
        return 0;
      }

      return volatility(this.combinedDailyReturns);
    },
    cagr: function() {
      if (this.combinedDailyReturns.length == 0) {
        return 0;
      }

      return cagr(this.combinedDailyReturns);
    },
    groups: function() {
      const groups = R.reduce(
        (acc, signal) => {
          const groupName = signal.signal_group;

          if (acc[groupName]) {
            acc[groupName] = [...acc[groupName], signal];
          } else {
            acc[groupName] = [signal];
          }

          return acc;
        },
        {},
        this.signals
      );

      return R.map((pair) => {
        return { name: pair[0], signals: pair[1] };
      }, R.toPairs(groups));
    },
    activeDatasets: function() {
      return R.filter((dataset) => {
        return this.selected.includes(dataset.label);
      }, this.datasets);
    },
    signalNames: function() {
      const { signals } = this;
      return R.map((signal) => signal.id_name, signals);
    },
    activeReturns: function() {
      const { activeDatasets } = this;
      return R.map((dataset) => {
        return R.map((point) => {
          return point.y;
        }, dataset.data);
      }, activeDatasets);
    },
    activeDailyReturns: function() {
      const active = R.filter((returns) => {
        return this.selected.includes(returns.name);
      }, this.allReturns);

      return R.map((x) => x.returns, active);
    },
    combinedDailyReturns: function() {
      const sum = R.reduce(R.add, 0);

      return R.map((dailyReturns) => {
        return sum(dailyReturns) / dailyReturns.length;
      }, zipMany(this.activeDailyReturns));
    },
    combinedSignal: function() {
      if (this.activeReturns.length == 0) {
        return null;
      }

      const sum = R.reduce(R.add, 0);

      const returns = R.map((dailyReturns) => {
        return sum(dailyReturns) / dailyReturns.length;
      }, zipMany(this.activeReturns));

      const xyPairs = R.zip(this.getActiveDates(), returns);

      const points = R.map((pair) => {
        return { x: pair[0], y: pair[1] };
      }, xyPairs);

      return {
        fill: false,
        label: "Combined",
        backgroundColor: "#01B050",
        pointRadius: 0,
        borderColor: "#01B050",
        data: points,
      };
    },
  },
};
</script>
