<template>
  <div>
    <div class="w-full">
      <div
        class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200 my-2 md:mr-1"
      >
        <div class="px-4 py-5 sm:px-6 flex justify-between ">
          <h3 class="text-lg leading-6 font-medium text-wave self-center">
            Other Statistics
          </h3>
        </div>

        <div class="p-4">
          <div class="flex">
            <button
              @click="selectData('ic')"
              :class="buttonClass('ic') + ' mr-2'"
            >
              IC
            </button>
            <button
              @click="selectData('qrtl')"
              :class="buttonClass('qrtl')"
            >
              Qrtl
            </button>
          </div>
          <div class="pt-3">
            <canvas id="otherStats" />
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="w-full">
      <div
        class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200 my-2 md:mr-1"
      >
        <div class="px-4 py-5 sm:px-6 flex justify-between ">
          <h3 class="text-lg leading-6 font-medium text-wave self-center">
            Parameters
          </h3>
        </div>

        <div class="flex">
          <div class="w-full p-4">
          <table class="w-full">
            <tbody>
              <tr>
                <td class="w-40">Min. Securities</td>
                <td class="w-20 font-bold">100</td>
              </tr>
              <tr>
                <td class="w-40">Min. Signal Threshold</td>
                <td class="w-20 font-bold">X</td>
              </tr>
              <tr>
                <td class="w-40">Spread Plot</td>
                <td class="w-20 font-bold">True</td>
              </tr>
              <tr>
                <td class="w-40">Specific Year</td>
                <td class="w-20 font-bold">All</td>
              </tr>
            </tbody>
          </table>
        </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import Chart from "chart.js";
// import { getRow } from "@/lib/chartHelpers";
// import ToggleButton from "./ToggleButton.vue";
import * as R from "ramda";

Chart.defaults.global.defaultFontFamily = "'Lato', 'sans-serif'";
Chart.defaults.global.defaultFontSize = 14;
Chart.defaults.global.defaultFontColor = "#1A202C";

export default {
  name: "StatsAndParams",
  data: () => {
    return {
      selected: "ic",
      otherStatsChart: null,
      colors: [
        ["#E7ECE4", "#E7ECE4"],
        ["#AFABAB", "#AFABAB"],
        ["#E3C3B5", "#E3C3B5"],
        ["#908292", "#908292"],
        ["#DEEBF7", "#DEEBF7"],
        ["#6DB8E1", "#6DB8E1"],
        ["#9EDEC6", "#9EDEC6"],
      ],
      ic: {
        data: [
          {
            value: 10,
            label: "Q1"
          },
          {
            value: 6,
            label: "Q2"
          },
          {
            value: 4,
            label: "Q3"
          },
          {
            value: 2,
            label: "Q4"
          },
          {
            value: -1,
            label: "Q5"
          }
        ]
      },
      qrtl: {
        data: [
          {
            value: 1,
            label: "Q1"
          },
          {
            value: 3,
            label: "Q2"
          },
          {
            value: 8,
            label: "Q3"
          },
          {
            value: 10,
            label: "Q4"
          }
        ]
      }
    };
  },
  mounted: function() {
    this.renderStats();
  },
  computed: {
    otherStatsData: function() {
      const data = this[this.selected].data

      const getLabels = R.map(R.prop("label"))
      const getValues = R.map(R.prop("value"))

      return {
        labels: getLabels(data),
        datasets: [{
          backgroundColor: this.colors[1][1],
          data: getValues(data),
        }]
      }
    }
  },
  watch: {
    otherStatsData: function() {
      this.otherStatsChart.data = this.otherStatsData
      this.otherStatsChart.update()
    }
  },
  methods: {
    selectData(name) {
      this.selected = name
    },
    buttonClass(name) { 
      return (
        "items-center px-4 py-1 border shadow-sm font-medium rounded-md focus:outline-none sm:text-sm " +
        ((name == this.selected)
          ? "bg-gray-dark text-white hover:bg-white hover:text-wave hover:border-gray-dark"
          : "bg-white border text-wave border-gray-dark hover:bg-gray-dark hover:text-white")
      );
    },
    renderStats() {
      let ctx = document.getElementById("otherStats");

      let chart = new Chart(ctx, {
        type: "bar",
        data: this.otherStatsData,
        options: {
          legend: {
            display: false
          },
          // animation: {
          //   duration: 0,
          // },
          aspectRatio: 3.5,
          scales: {
            yAxes: [
              {
                ticks: {
                  // stepSize: 10,
                  callback: (x) => `${x}`
                },
              },
            ],
          },
        },
      });

      this.otherStatsChart = chart;
    },
  }  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
