<template>
  <button :class="currentClass" @click="onClick"><slot></slot></button>
</template>

<script>
export default {
  name: "ToggleButton",
  props: {
    clickAction: Function,
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentClass: function() {
      return (
        "items-center mb-1 px-2 py-1 border shadow-sm rounded-md focus:outline-none text-xs " +
        (this.active
          ? "bg-gray-dark text-white"
          : "bg-white border text-wave border-gray-dark")
      );
    },
  },
  methods: {
    onClick: function() {
      this.clickAction(this.active);
    },
  },
};
</script>
