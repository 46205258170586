<template>
  <div>
    <nav-bar />
    <div class="py-10">
      <header>
        <div
          class="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between align-middle"
        >
          <div class="flex">
            <h1 class="text-3xl font-bold leading-tight text-wave">
              Add Strategy
            </h1>
          </div>
        </div>
      </header>
      <main>
        <div class="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div
            class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200 px-4 py-5 sm:px-6"
          >
            <form class="space-y-8 divide-y divide-gray-200">
              <div class="space-y-8 divide-y divide-gray-200">
                <div>
                  <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                      Strategy Information
                    </h3>
                    <p class="mt-1 text-sm text-gray-500">
                      <!-- Use a permanent address where you can receive mail. -->
                    </p>
                  </div>
                  <div
                    class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6"
                  >
                    <div class="sm:col-span-4">
                      <label
                        for="email"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Strategy Name
                      </label>
                      <div class="mt-1">
                        <input
                          type="text"
                          class="shadow-sm focus:ring-gray-500 focus:border-gray-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>

                    <div class="sm:col-span-3">
                      <label
                        for="email"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Strategy Group
                      </label>
                      <select id="email" name="country" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm">
                        <option>conventional_trend</option>
                        <option>conventional_volatility</option>
                        <option>conventional_analystestimates</option>
                        <option>conventional_value</option>
                      </select>
                    </div>

                     <div class="sm:col-span-3">
                      <label
                        for="email"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Strategy Type
                      </label>
                      <select id="email" name="country" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm">
                        <option>default_examples</option>
                      </select>
                    </div>


                    <div class="sm:col-span-6">
                      <label
                        for="email"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Strategy Description
                      </label>
                      <div class="mt-1">
                        <input
                          type="text"
                          class="shadow-sm focus:ring-gray-500 focus:border-gray-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>

                    <div class="sm:col-span-6">
                      <label
                        for="formula"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Strategy Formula
                      </label>
                      <div class="mt-1">
                        <prism-editor class="my-editor" v-model="code" :highlight="highlighter" line-numbers></prism-editor>

                        <!-- <textarea
                          id="formula"
                          name="formula"
                          rows="3"
                          class="shadow-sm focus:ring-gray-500 focus:border-gray-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        ></textarea> -->
                      </div>
                    </div>

                    <div class="sm:col-span-6">
                      <label
                        for="about"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Strategy Rationale
                      </label>
                      <div class="mt-1">
                        <textarea
                          id="about"
                          name="about"
                          rows="4"
                          class="shadow-sm focus:ring-gray-500 focus:border-gray-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        ></textarea>
                        <p class="mt-2 text-sm text-gray-500">Write rationale for proposed signal.</p>
                      </div>
                    </div>

                    <div class="sm:col-span-6">
                      <label for="cover_photo" class="block text-sm font-medium text-gray-700">
                        Attach Research
                      </label>
                      <div class="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                        <div class="space-y-1 text-center">
                          <!-- <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg> -->
                          <svg xmlns="http://www.w3.org/2000/svg" class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                          </svg>
                          <div class="flex text-sm text-gray-600">
                            <label for="file-upload" class="relative cursor-pointer bg-white rounded-md font-medium text-gray-600 hover:text-gray-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-gray-500">
                              <span>Upload a file</span>
                              <input id="file-upload" name="file-upload" type="file" class="sr-only">
                            </label>
                            <p class="pl-1">or drag and drop</p>
                          </div>
                          <p class="text-xs text-gray-500">
                            PNG, JPG, GIF, PDF up to 10MB
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="sm:col-span-6">
                      <label
                        for="about"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Additional Comments
                      </label>
                      <div class="mt-1">
                        <textarea
                          id="about"
                          name="about"
                          rows="3"
                          class="shadow-sm focus:ring-gray-500 focus:border-gray-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="pt-5 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div class="sm:col-span-3">
                  <label
                    for="email"
                    class="block text-sm font-medium text-gray-700"
                  >
                    Submitted By
                  </label>
                  <select id="email" name="country" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm">
                    <option>Patrick MacDonald</option>
                    <option>Brian Neibergal</option>
                  </select>
                </div>

                <div class="sm:col-span-3 flex justify-end">
                  <div class="self-end">
                    <button
                      type="button"
                      class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                    >
                      Submit for review
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<style>
  .height-200{
    height: 200px  
  }
  
  .my-editor {
    /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
    background: #2d2d2d;
    color: #ccc;

    border-radius: 0.375rem;

    /* you must provide font-family font-size line-height. Example:*/
    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 20px;
  }

  /* optional class for removing the outline */
  .prism-editor__textarea:focus {
    outline: none;
  }
</style>

<script>
import NavBar from "../../components/NavBar.vue";

// import Prism Editor
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-python";
import "prismjs/themes/prism-okaidia.css"; // import syntax highlighting styles

export default {
  name: "AddSignal",
  components: {
    NavBar,
    PrismEditor,
  },
  data: () => ({ code: 'def calc_fcfmktcap_example(numeric_data, dates, insts, vals):\n' +
    '\tsignal_vals = np.zeros(len(insts))*np.NaN #init to nans\n\n' +
    '\tfcf_ix       = vals.index("free_cash_flow")\n' +
    '\ttotshares_ix = vals.index("tot_shares")\n' +
    '\tpx_close_ix  = vals.index("px_close")\n\n' +
    '\tfcfs      = fwd_fill(numeric_data[:,:,fcf_ix])\n' +
    '\ttotshares = fwd_fill(numeric_data[:,:,totshares_ix])\n\n' +
    '\tsignal_vals[:] =\n\t\t (fcfs[-1,:] / (totshares[-1,:] * numeric_data[-1,:,px_close_ix]))\n\n' +
    '\tsignal_vals[np.isinf(signal_vals)] = np.nan\n' +
    '\tsignal_vals[signal_vals==0] = np.nan\n\n' +
    '\treturn signal_vals' }),
  methods: {
    highlighter(code) {
      return highlight(code, languages.py); // languages.<insert language> to return html with markup
    },
  },
};
</script>
