function getRow(matrix, row) {
  return matrix[row];
}

function getCol(matrix, col) {
  let res = [];

  for (let row of matrix) {
    res = [...res, row[col]];
  }

  return res;
}

export { getCol, getRow };
