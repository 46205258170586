<template>
  <div>
    <nav-bar />
    <div class="py-10">
      <header>
        <div
          class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between align-middle"
        >
          <div class="flex">
            <h1 class="text-3xl font-bold leading-tight text-wave">
              Strategy Details
            </h1>
            <select
              v-model="selectedStrategy"
              class="shadow-sm focus:ring-gray-500 focus:border-gray-500 block text-md border-gray-500 bg-gray-200 rounded-md ml-2 py-1"
              :disabled="loading"
            >
              <option v-for="id in stategyIds" :key="id" :value="id">
                {{ id }}
              </option>
            </select>
          </div>

          <!-- <h4 class="self-center text-lg text-wave">
            {{ strategyDescription }}
          </h4> -->
        </div>
      </header>
      <main>
        <div class="max-w-7xl mx-auto px-6 lg:px-8 py-4">
          <div class="w-full md:w-1/2">
            <!-- <div>
              <h3 class="text-lg font-semibold text-wave mb-5">
                {{ selectedStrategyData["name"] }}
              </h3>
            </div> -->
            <div class="flex flex-wrap justify-between text-sm">
              <div class="mr-2">
                <p>
                  <span class="font-semibold">Category:</span>
                  {{ selectedStrategyData["Category"] }}
                </p>
                <p>
                  <span class="font-semibold">Type:</span>
                  {{ selectedStrategyData["Type"] }}
                </p>
                <p>
                  <span class="font-semibold">Applicable Product:</span>
                  {{ selectedStrategyData["Applicable Product"] }}
                </p>
              </div>
              <div>
                <p>
                  <span class="font-semibold">Status: </span>
                  <span :class="getStatusColor(selectedStrategyData['Status'])">{{
                    selectedStrategyData["Status"]
                  }}</span>
                </p>
                <p>
                  <span class="font-semibold">Lead: </span>
                  {{ selectedStrategyData["Lead"] }}
                </p>
              </div>
            </div>
          </div>
          <div class="mt-5">
            <h3 class="text-lg font-semibold text-wave mb-5">
              Backtest Section
            </h3>
          </div>
          <div class="grid grid-cols-2 gap-4">
            <cumulative-growth :strategy="selectedStrategy"></cumulative-growth>
            <!-- <div
              v-for="number in numbers"
              :key="`chart_${number}`"
              class="border-gray-dark border-2 p-3 rounded h-80"
            >
              Chart {{ number }}
            </div> -->
            <performance :strategy="selectedStrategy"></performance>
            <annual-returns :strategy="selectedStrategy"></annual-returns>
            <risk-return :strategy="selectedStrategy"></risk-return>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import NavBar from "../../components/NavBar.vue";
import * as R from "ramda";

import AnnualReturns from "./AnnualReturns.vue";
import Performance from "./Performance.vue";
import RiskReturn from "./RiskReturn.vue";
import CumulativeGrowth from "./CumulativeGrowth.vue";

import StrategyLibrary from "../../lib/StrategyLibrary";

export default {
  name: "StrategyDetails",

  components: {
    NavBar,
    Performance,
    CumulativeGrowth,
    AnnualReturns,
    RiskReturn
  },

  props: ["strategy"],

  data: () => {
    return {
      selectedStrategy: "China_ARPBL_M1",
      stategyIds: [],
    };
  },

  mounted: function () {
    this.loadStrategyIds();

    if (this.strategy) {
      this.selectedStrategy = this.strategy;
    }
  },

  watch: {
    selectedStrategy() {},
  },

  computed: {
    loading: function () {
      return this.responseFunctionLoading || this.returnsLoading;
    },

    selectedStrategyData() {
      return StrategyLibrary.getStrategyDetails(this.selectedStrategy);
    },

    strategyDescription: function () {
      const current = this.selectedStrategyData;

      if (current) {
        // return current["description"];
        return "";
      } else {
        return "";
      }
    },

    numbers() {
      return R.range(0, 6);
    },
  },
  methods: {
    loadStrategyIds() {
      this.stategyIds = StrategyLibrary.getStrategyNames();
    },

    getStatusColor(status) {
      if (status == "Active") {
        return "text-green-500";
      }

      if (status == "Deployment") {
        return "text-blue-500";
      }

      return "text-yellow-500";
    },
  },
};
</script>
