// import * as R from "ramda"

// const benchmarkData = require("../data/benchmark_annualreturn.json")

export default class Constants {
  static benchmarkNames() {
    // const getBenchmarkNames = R.pipe(R.values, R.head, R.keys)
    // return getBenchmarkNames(benchmarkData)

    return ["CSI_300",
    "CSI_500",
    "KOSPI_200",
    "SP_500"]
  }
}