<template>
  <div
    class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200 my-2 md:mr-1"
  >
    <div class="px-4 py-5 sm:px-6 flex justify-between ">
      <div class="self-center">
        <h3 class="text-lg leading-6 font-medium text-wave self-center">
          {{ title }}
        </h3>
        <h5 class="text-lg leading-6 font-medium text-gray-dark self-center">
          {{ subtitle }}
        </h5>
      </div>
      <div>
        <div class="mt-1">
          <select
            v-model="alg"
            class="shadow-sm focus:ring-gray-500 focus:border-gray-500 block w-full text-md border-gray-500 bg-gray-200 rounded-md py-1 self-center"
          >
            <option value="t">t-stat</option>
            <option value="g">G</option>
            <option value="err">mu+errors</option>
          </select>
        </div>
      </div>
    </div>

    <div class="flex flex-col p-4">
      <div class="w-full">
        <div class="pl-2">
          <canvas :id="chartId" />
        </div>
      </div>
      <div class="px-2 py-2 flex justify-around">
        <toggle-button
          v-for="i in Array(tauLabels ? tauLabels.length : 0).keys()"
          :key="i + 'option'"
          :clickAction="() => chooseZ(i)"
          :active="isActiveZ(i)"
          >tau={{ tauLabels ? tauLabels[i] : "" }}</toggle-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";
import { getRow } from "@/lib/chartHelpers";
import ToggleButton from "./ToggleButton.vue";
import * as R from "ramda";

Chart.defaults.global.defaultFontFamily = "'Lato', 'sans-serif'";
Chart.defaults.global.defaultFontSize = 14;
Chart.defaults.global.defaultFontColor = "#1A202C";

export default {
  name: "ResponseChart",
  data: () => {
    return {
      activeZ: [1],
      alg: "t",
      colors: [
        ["#E7ECE4", "#E7ECE4"],
        ["#AFABAB", "#AFABAB"],
        ["#E3C3B5", "#E3C3B5"],
        ["#908292", "#908292"],
        ["#DEEBF7", "#DEEBF7"],
        ["#6DB8E1", "#6DB8E1"],
        ["#9EDEC6", "#9EDEC6"],
      ],
    };
  },
  props: {
    data: Object,
    title: String,
    chartId: String,
    subtitle: String
  },
  mounted: function() {
  },
  components: {
    ToggleButton,
  },
  computed: {
    tauLabels: function() {
      return this.data.dim11_labels;
    },
    percentileLabels: function() {
      return this.data.dim2_labels;
    },
    response_fn_mean: function() {
      return this.data.response_fn_mean;
    },
    response_fn_stdv: function() {
      return this.data.response_fn_stdv;
    },
    datasets: function() {
      return R.map(this.getDataset, this.activeZ);
    },
  },
  watch: {
    datasets: function() {
      if (this.chart) {
        this.chart.data.datasets = this.datasets;
        this.chart.update();
      } else {
        this.chart = this.renderChart();
      }
    },
  },
  methods: {
    getDataset(index) {
      return {
        fill: false,
        label: "tau=" + (this.tauLabels ? this.tauLabels[index] : index),
        backgroundColor: this.colors[index][0],
        borderColor: this.colors[index][1],
        data: this.getPoints(index),
      };
    },
    getAlg() {
      if (this.alg === "t") {
        return (mean, std_dv) => mean / std_dv;
      } else if (this.alg === "g") {
        return (mean, std_dv) => mean - 0.5 * (std_dv ^ 2);
      } else {
        return (mean, std_dv) => mean + std_dv;
      }
    },
    isActiveZ(z) {
      return R.includes(z, this.activeZ);
    },
    chooseZ(i) {
      if (!this.isActiveZ(i)) {
        this.activeZ = R.sort((a, b) => a - b, R.append(i, this.activeZ));
      } else {
        this.activeZ = R.filter((x) => x !== i, this.activeZ);
      }
    },
    getYVals(index) {
      const a = getRow(this.response_fn_mean, index);
      const b = getRow(this.response_fn_stdv, index);
      let res = [];

      const selectedAlg = this.getAlg();

      for (let i in a) {
        res = [...res, selectedAlg(a[i], b[i])];
      }

      return res;
    },
    getPoints(index) {
      const yVals = this.getYVals(index);

      let data = [];

      for (let i in yVals) {
        data = [...data, { x: this.percentileLabels[i], y: yVals[i] }];
      }

      return data;
    },
    renderChart() {
      let ctx = document.getElementById(this.chartId);

      let chart = new Chart(ctx, {
        type: "line",
        data: {
          labels: this.percentileLabels,
          datasets: this.datasets,
        },
        options: {
          plugins: {
            legend: {
                onClick: function() {}
            },
          },
          aspectRatio: 1.5,
          fill: false,
          // animation: {
          //   duration: 0,
          // },
          scales: {
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "Forward Return"
                }
              },
            ],
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "Signal Strength (percentile)"
                }
              },
            ],
          },
        },
      });

      return chart;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
