<template>
  <div>
    <nav-bar />
    <div class="py-10">
      <header>
        <div
          class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between align-middle"
        >
          <div class="flex">
            <h1 class="text-3xl font-bold leading-tight text-wave">
              Strategy Library
            </h1>

            <select
              v-model="selectedStatus"
              class="shadow-sm focus:ring-gray-500 focus:border-gray-dark block text-md border-gray-dark bg-gray-200 rounded-md ml-2 py-1 self-center"
            >
              <option v-for="group in groupOptions" :key="group" :value="group">
                {{ group }}
              </option>
            </select>
          </div>

          <h4 class="self-center text-lg text-wave hidden md:block">
            Select a strategy to view details
          </h4>
        </div>
      </header>
      <main>
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div class="flex flex-wrap">
            <div
              class="w-full"
              v-for="strategy in filteredStrategies"
              :key="strategy['name']"
            >
              <router-link :to="'/strategy/' + strategy['id']">
                <div
                  class="bg-white overflow-hidden shadow rounded-lg m-2 p-5 border-transparent hover:bg-gray-light cursor-pointer hover:border-gray-dark border-2 flex flex-1 justify-between"
                >
                  <div class="w-full">
                    <div>
                      <h3 class="text-lg font-semibold text-wave mb-5">
                        {{ idToTitle(strategy["name"]) }}
                      </h3>
                    </div>
                    <div class="flex flex-wrap justify-left text-sm">
                      <div class="mr-2 w-1/2">
                        <p>
                          <span class="font-semibold">Category:</span>
                          {{ strategy["Category"] }}
                        </p>
                        <p>
                          <span class="font-semibold">Type:</span>
                          {{ strategy["Type"] }}
                        </p>
                        <p>
                          <span class="font-semibold">Applicable Product:</span>
                          {{ strategy["Applicable Product"] }}
                        </p>
                      </div>
                      <div>
                        <p>
                          <span class="font-semibold">Status: </span>
                          <span :class="getStatusColor(strategy['Status'])">{{ strategy["Status"] }}</span>
                        </p>
                        <p>
                          <span class="font-semibold">Lead: </span>
                          {{strategy["Lead"]}}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="md:flex self-center hidden items-center"
                  >
                    <p>View Details</p>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import * as R from "ramda";
import NavBar from "../../components/NavBar.vue";

import StrategyLibrary from "../../lib/StrategyLibrary";

export default {
  name: "StrategyLibrary",
  data: () => {
    return {
      strategies: [],
      selectedStatus: "all",
    };
  },
  components: {
    NavBar,
  },
  mounted: function () {
    this.loadStrategies();
  },
  methods: {
    // getStrategies: function() {
    //   fetch(`${this.apiUrl}/strategy_library`)
    //     .then((res) => res.json())
    //     .then((res) => {
    //       this.strategys = res["strategy_list"];
    //     });
    // },
    setStrategies(strategies) {
      this.strategies = R.clone(strategies);
    },

    getStrategies() {
      return StrategyLibrary.getAllStrategies()
    },

    loadStrategies() {
      const strategies = StrategyLibrary.getAllStrategies()
      this.setStrategies(strategies);
    },
    idToTitle: function (id) {
      const capFirst = (str) => str.charAt(0).toUpperCase() + str.slice(1);

      return R.join(" ", R.map(capFirst, R.split("_", id)));
    },
    getStatusColor(status) {
      if (status == "Active") {
        return "text-green-500";
      }

      if (status == "Deployment") {
        return "text-blue-500";
      }

      return "text-yellow-500";
    },
  },
  computed: {
    filteredStrategies: function () {
      if (this.selectedStatus === "all") {
        return this.strategies;
      }

      const isSelected = (strategy) => strategy.region === this.selectedStatus;
      return R.filter(isSelected, this.strategies);
    },
    groupOptions: function () {
      const getGroup = (strategy) => strategy.region;
      return ["all", ...R.uniq(R.map(getGroup, this.strategies))];
    },
  },
};
</script>
