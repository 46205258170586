import * as R from "ramda";

const strategyLibrary = require("../data/strategy_library.json");

export default class StrategyLibrary {
  static getStrategyNames() {
    return R.keys(strategyLibrary);
  }

  static getStrategyDetails(strategyName) {
    return { name: strategyName, id: strategyName,  ...strategyLibrary[strategyName] };
  }

  static getAllStrategies() {
    return StrategyLibrary.getStrategyNames().map(
      StrategyLibrary.getStrategyDetails
    );
  }
}
