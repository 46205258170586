<template>
  <table class="min-w-full divide-y divide-gray-300">
    <thead class="">
      <tr>
        <template v-for="heading in headings">
          <td
            v-if="heading.key == primaryKey"
            :key="heading.id"
            class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
          >
            {{ heading.label }}
          </td>
          <td
            v-else
            :key="heading.id + 'standard'"
            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            {{ heading.label }}
          </td>
        </template>
      </tr>
    </thead>
    <tbody class="bg-white">
      <tr v-for="row in rows" :key="row.id">
        <template v-for="cell in row.cells">
          <td
            v-if="cell.key == primaryKey"
            :key="cell.id"
            class="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
          >
            {{ cell.value }}
          </td>
          <td
            v-else
            :key="cell.id + 'standard'"
            class="whitespace-nowrap px-3 py-2 text-sm text-gray-500"
            :style="{backgroundColor: cell.color}"
          >
            {{ cell.value }}
          </td>
        </template>
      </tr>
    </tbody>
  </table>
</template>

<script>
import * as R from "ramda";
import { v4 } from "uuid";

export default {
  name: "JTable",

  props: {
    items: {
      default: []
    },
    fields: {
      default: {}
    },
    primaryKey: {
      default: "year"
    }
  },

  methods: {
    getCells(row) {
      const addIds = R.map((value) => {
        return {
          value,
          id: v4(),
        };
      });

      return R.pipe(addIds)(row);
    },

    processCells(row) {
      const noFormatter = row.indicator == "Sharpe" || row.indicator == "Sortino"

      return R.map((field) => {
        return this.processCell(row, field, noFormatter);
      }, this.fields);
    },

    processCell(row, field, noFormatter = false) {
      const { key } = field;

      let formatter = field.formatter || ((x) => x);
      formatter = noFormatter && field.formatter ? (x) => x.toFixed(2) : formatter

      const colorFunc = field.colorFunc || (() => "#FFFFFF")

      return {
        value: formatter(row[key]),
        color: colorFunc(row[key]),
        id: v4(),
        key,
      };
    },
  },

  computed: {
    rows() {
      return R.map((item) => {
        return {
          cells: this.processCells(item),
          id: v4(),
        };
      }, this.items);
    },

    headings() {
      return R.map((field) => {
        const id = v4();
        return R.assoc("id", id, field);
      }, this.fields);
    },
  },
};
</script>
