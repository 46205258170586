import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import AddSignal from "../views/AddSignal.vue";
import SignalLibrary from "../views/SignalLibrary.vue";
import SignalCombination from "../views/SignalCombination.vue";
import Login from "../views/Login.vue";

import StrategyLibrary from "../views/Strategy/StrategyLibrary.vue";
import StrategyDevelopment from "../views/Strategy/StrategyDevelopment.vue";
import StrategyDetails from "../views/Strategy/StrategyDetails.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "SignalLibrary",
    component: SignalLibrary,
    meta: { requiresAuth: true }
  },
  {
    path: "/dashboard/:signal",
    props: true,
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true }
  },
  // {
  //   path: "/strategy/:strategy",
  //   props: true,
  //   name: "StrategyDetails",
  //   component: StrategyDetails,
  //   meta: { requiresAuth: true }
  // },
  {
    path: "/strategy",
    props: true,
    name: "StrategyLibrary",
    component: StrategyLibrary,
    meta: { requiresAuth: true }
  },
  {
    path: "/strategy/:strategy",
    props: true,
    name: "StrategyDetails",
    component: StrategyDetails,
    meta: { requiresAuth: true }
  },
  {
    path: "/add-strategy",
    props: true,
    name: "StrategyDevelopment",
    component: StrategyDevelopment,
    meta: { requiresAuth: true }
  },
  {
    path: "/combination",
    name: "SignalCombination",
    component: SignalCombination,
    meta: { requiresAuth: true }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { requiresAuth: false }
  },
  {
    path: "/add-signal",
    props: true,
    name: "AddSignal",
    component: AddSignal,
    meta: { requiresAuth: true }
  },
];

const router = new VueRouter({
  mode: "history",
  linkExactActiveClass: "bg-gray-100 text-wave",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
      if (localStorage.getItem('authed') == null) {
          next({
              path: '/login',
              params: { nextUrl: to.fullPath }
          })
      } else {
        next()
      }
  } else {
      next()
  }
})

export default router;
